import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import BlogPosts from "../components/BlogPosts"

const IndexPage = ({ data }) => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Nunito:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>
      {/* <IntroModal /> */}
      <Layout>
        <BlogPosts posts={data.blogposts.edges} />
      </Layout>
    </>
  )
}

export const data = graphql`
  query AllBlogPosts {
    blogposts: allContentfulPost(sort: { order: ASC, fields: creationDate }) {
      edges {
        node {
          slug
          title
          category {
            name
          }
          id: contentful_id
          featuredImage {
            fluid {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          creationDate(formatString: "MMMM D YYYY")
          longDescription {
            longDescription
          }
          mainPost {
            childMdx {
              timeToRead
            }
          }
        }
      }
    }
  }
`
export default IndexPage
