import React from "react"
/** @jsx jsx */
import { jsx, Container, Box } from "theme-ui"
import Social from "../components/Social"

// COMPONENTS
import BlogPost from "./BlogPost"
const socialNav = {
  position: "fixed",
  left: 0,
  top: "50%",
  zIndex: 10000,
  display: ["none", "none", "none", "block"],
}
const BlogPosts = ({ posts }) => {
  return (
    <>
      <Box sx={socialNav} ml={3}>
        <Social flexDirection="column" />
      </Box>
      <Container>
        {posts.map(({ node }) => {
          return <BlogPost key={node.id} data={node} />
        })}
      </Container>
    </>
  )
}

export default BlogPosts
