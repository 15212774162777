import React from "react"
import Image from "gatsby-image"
/** @jsx jsx */
import { jsx, Heading, Box, Flex, Text } from "theme-ui"
import { Link } from "gatsby"

const postWrapper = {
  flexWrap: "wrap",
  height: ["400px", "400px", "256px", "256px"],
  width: "100%",
  overflow: "hidden",
  transition: "0.2s",
  transitionTimingFunction: "ease-out",
  "&:hover": {
    boxShadow: "0px 0.5px 10px 0.5px #00000055",
    cursor: "pointer",
  },
}

const imgPanel = {
  width: "100%",
}
const subheading = {
  mr: 2,
  alignSelf: "center",
}
const description = {
  fontSize: 1,
  lineHeight: "body",
  // custom
  overflow: "hidden",
  textOverflow: "ellipsis",
}

const BlogPost = ({ data }) => {
  const {
    title,
    category,
    creationDate,
    featuredImage,
    slug,
    longDescription,
    mainPost,
  } = data
  const timeToRead = mainPost.childMdx.timeToRead

  return (
    <Link style={{ textDecoration: "none", color: "inherit" }} to={`/${slug}`}>
      <Flex sx={postWrapper} py={3}>
        <Flex
          sx={{
            width: ["100%", "100%", "50%", "50%"],
            height: ["50%", "50%", "100%", "100%"],
          }}
        >
          <Image fluid={featuredImage.fluid} style={imgPanel} />
        </Flex>
        <Flex
          sx={{
            flexDirection: "column",
            width: ["100%", "100%", "50%", "50%"],
            height: ["50%", "50%", "100%", "100%"],
          }}
          px={3}
          py={1}
        >
          <Heading as="h2" py={2}>
            {title}
          </Heading>
          <Flex sx={{ justifyContent: "space-between", mb: 3 }}>
            <Flex>
              <Text
                sx={{
                  ...subheading,
                  ...{
                    fontSize: [0, 0, 1, 1],
                    color: "primary",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  },
                }}
              >
                {category.name}
              </Text>
              <Text
                sx={{
                  ...subheading,
                  ...{ color: "muted", fontStyle: "italic", fontSize: 0 },
                }}
              >
                {creationDate}
              </Text>
            </Flex>
            <Box>
              <Text
                sx={{
                  ...subheading,
                  ...{
                    color: "muted",
                    fontStyle: "italic",
                    fontSize: 0,
                    border: "2px solid lightsteelblue",
                    py: "2px",
                    px: [2, 2, 3, 3],
                    borderRadius: "50px",
                  },
                }}
              >
                {timeToRead} Min Read
              </Text>
            </Box>
          </Flex>

          <Text sx={description}>{longDescription.longDescription}</Text>
        </Flex>
      </Flex>
    </Link>
  )
}

export default BlogPost
